import React, { useState, useEffect } from "react";
import Icons from "./icons";
import { getForecast } from "@api";

import "./style.css";

function Forecast() {
  const [forecast, setForecast] = useState({});

  async function fetchEvents() {
    const response = await getForecast();
    setForecast(response);
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  const itData = forecast.it_forecast;
  const enData = forecast.en_forecast;

  return (
    <>
      <div className="forecast">
        <div className="forecast__city">
          <Icons city="Avellino IT" event={itData} />
        </div>
        <div className="forecast__city">
          <Icons city="London UK" event={enData} />
        </div>
      </div>
    </>
  );
}

export default Forecast;
