import React from "react";

import cloudSource from "@assets/images/weather--cloud.png";
import sunSource from "@assets/images/weather--sun.png";
import moonSource from "@assets/images/weather--moon.png";
import rainSource from "@assets/images/weather--rain.png";
import snowSource from "@assets/images/weather--snow.png";
import stormSource from "@assets/images/weather--storm.png";
import windSource from "@assets/images/weather--wind.png";

function Icons(props) {
  let source = cloudSource;
  let alt = "Cloud";

  if (
    props.event != null &&
    props.event.weather &&
    props.event.weather.length > 0
  ) {
    let weather = props.event.weather[0].main;

    const hours = new Date(
      (props.event.dt + props.event.timezone) * 1000
    ).getHours();

    const isDay = hours > 6 && hours < 20;

    switch (weather) {
      case "Clear":
        if (isDay) {
          source = sunSource;
          alt = "Sun";
        } else {
          source = moonSource;
          alt = "Moon";
        }
        break;
      case "Drizzle":
      case "Rain":
        source = rainSource;
        alt = "Rain";
        break;
      case "Snow":
        source = snowSource;
        alt = "Snow";
        break;
      case "Squall":
        source = windSource;
        alt = "Wind";
        break;
      case "Thunderstorm":
        source = stormSource;
        alt = "Storm";
        break;
      default:
      case "Clouds":
        source = cloudSource;
        alt = "Cloud";
    }
  }

  return (
    <>
      <img width="32" src={source} alt={alt} />
      <p>{props.city}</p>
    </>
  );
}
export default Icons;
