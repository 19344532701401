import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

//import Container from "@components/Container";
import Grid from "@components/Grid";
import Forecast from "@components/Forecast";

import { ReactComponent as TwitterIcon } from "@assets/images/ico_twitter.svg";
import { ReactComponent as InstagramIcon } from "@assets/images/ico_instagram.svg";
import { ReactComponent as FacebookIcon } from "@assets/images/ico_facebook.svg";
import { ReactComponent as LinkedinIcon } from "@assets/images/ico_linkedin.svg";

import "./style.css";

const Footer = props => {
  const [isMounted, setIsMounted] = useState(false);
  const footer = useRef(null);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const header = document.querySelector("header");
    //const sideNav = document.querySelector(".side-nav__open-button");

    const hide = event => {
      header.removeEventListener("transitionend", hide);
      header.style.display = "none";
    };

    const intersectionObserver = new IntersectionObserver(
      function(entries) {
        header.removeEventListener("transitionend", hide);

        if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.2) {
          header.addEventListener("transitionend", hide);
          header.style.opacity = 0;
          /*
          sideNav.style.opacity = 0;
          sideNav.style.pointerEvents = "none"; */
        } else {
          header.style.display = "";
          // This is for to force a repaint
          /* eslint-disable no-unused-expressions */
          header.scrollTop;
          header.style.opacity = null;
          /*
          sideNav.style.opacity = null;
          sideNav.style.pointerEvents = null; */
        }
      },
      { threshold: 0.2 }
    );
    // start observing
    intersectionObserver.observe(document.querySelector("footer"));

    return () => {
      intersectionObserver.disconnect();
    };
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <footer ref={footer} className="cursor-color cursor-color--white">
        <Grid.Container fluid>
          <div className="footer-content">
            <div className="top-footer">
              <Forecast />
              <div className="social">
                <a
                  href="https://twitter.com/rollstudio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.instagram.com/rollstudio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.facebook.com/rollstudio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/roll-studio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedinIcon />
                </a>
              </div>
            </div>
            <div className="bottom-footer">
              <div>
                <p>
                  © 2022 Roll Studio Ltd. Read our{" "}
                  <Link to="/privacy">privacy and cookies policy</Link>.
                </p>
              </div>
              <div className="contact-us">
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </Grid.Container>
      </footer>
    </>
  );
};

export default Footer;
